import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RiExpandLeftLine, RiExpandRightLine, RiLogoutCircleLine, RiAddLine } from 'react-icons/ri';
import { MdOutlineAdminPanelSettings } from 'react-icons/md';
import { IoChatbubblesOutline } from 'react-icons/io5';
import logo from './icon/site-logo-gray-2077759815.png';

const Sidebar = ({ sidebarOpen, setSidebarOpen, handleLogout, role }) => {
  const navigate = useNavigate();

  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
    localStorage.setItem('chatSidebarOpen', JSON.stringify(!sidebarOpen));
  };

  return (
    <div className={`fixed flex flex-col justify-between h-full transition-all duration-300 ${sidebarOpen ? 'w-64' : 'w-20'} bg-gray-200 shadow-inner`}>
      <div className="p-4 flex flex-col items-start">
        <button onClick={handleSidebarToggle} className="text-gray-600">
          {sidebarOpen ? (
            <RiExpandLeftLine className="text-3xl bg-gray-300 p-1 rounded-full hover:bg-orange-200 transition-colors duration-300" />
          ) : (
            <RiExpandRightLine className="text-3xl bg-gray-300 p-1 rounded-full hover:bg-orange-200 transition-colors duration-300" />
          )}
        </button>
        {sidebarOpen && <img src={logo} alt="Company Logo" className="my-5 w-32 transition-all duration-300" />}
        <div className="flex items-center mt-4">
          <button onClick={() => navigate('/chat')} className="text-gray-600 flex items-center font-semibold text-sm font-sans">
            <span className="bg-gray-300 p-1 rounded-full mr-2 hover:bg-orange-200 transition-colors duration-300">
              <IoChatbubblesOutline className="text-2xl" />
            </span>
            {sidebarOpen && <span className="font-sans">Chat</span>}
          </button>
        </div>
      </div>
      <div className="p-4 flex items-center">
        <button onClick={handleLogout} className="text-gray-600 flex items-center font-semibold text-md font-sans">
          <span className="bg-gray-300 p-1 rounded-full mr-2 hover:bg-orange-200 transition-colors duration-300">
            <RiLogoutCircleLine className="text-2xl" />
          </span>
          {sidebarOpen && <span className="font-sans">Logout</span>}
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
